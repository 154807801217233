/* Main container */
.mint-page-container-mint {
    padding: 2rem;
    background-color: #000;
    color: #fff;
    font-family: 'Inter', sans-serif;
  }
  
  /* Header styling */
  .header-mint {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  
  .collection-image-mint {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .collection-info-mint {
    flex: 1;
  }
  
  .collection-title-mint {
    font-size: 2rem;
    color: #00bfff;
  }
  
  .collection-details-mint {
    color: #aaa;
  }
  
  .links-mint {
    margin-top: 0.5rem;
  }
  
  .market-link-mint {
    color: #00bfff;
    margin-right: 1rem;
    text-decoration: none;
    font-weight: bold;
  }
  
  .market-link-mint:hover {
    color: #005bb5;
  }
  
  /* Mint info */
  .mint-info {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
  }
  
  .progress-section-mint {
    flex: 1;
  }
  
  .progress-bar-mint {
    background-color: #333;
    border-radius: 8px;
    overflow: hidden;
    height: 12px;
    margin: 0.5rem 0;
  }
  
  .progress-fill-mint {
    background-color: #00bfff;
    height: 100%;
    transition: width 0.3s ease;
  }
  
  .progress-text-mint {
    font-size: 0.85rem;
    color: #aaa;
  }
  
  .public-card-mint {
    background-color: #111;
    border-radius: 8px;
    padding: 1rem;
    width: 200px;
  }
  
  .status-active-mint {
    color: #00ff00;
    font-weight: bold;
  }
  
  /* Mint controls */
  .mint-controls-mint {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  #mint-slider-mint {
    width: 150px;
  }
  
  .mint-count-mint {
    font-size: 1.2rem;
    color: #00bfff;
  }
  
  .mint-button {
    background-color: #00bfff;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .mint-button:hover {
    background-color: #005bb5;
  }
  
  /* Minted NFTs */
  .minted-nfts {
    margin-top: 3rem;
  }
  
  .nft-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }
  
  .nft-card {
    background-color: #111;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
  }
  
  .nft-card img {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 500px) {
   
    .collection-image-mint {
        width: 100px;
        height: 100px;
        border-radius: 8px;
        object-fit: cover;
      }
      
      .collection-info-mint {
        flex: 1;
      }
      
      .collection-title-mint {
        font-size: 2rem;
        color: #00bfff;
        font-size: 20px;
      }
      
      .collection-details-mint {
        color: #aaa;
      }
    
  }
  