/* Main container styling */
.faq-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 30px;
    background-color: #000;
    color: #fff;
    font-family: 'Inter', sans-serif;
  }
  
  /* Title and subtitle */
  .faq-title {
    font-size: 2rem;
    color: #00bfff;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .faq-subtitle {
    font-size: 1.2rem;
    color: #ccc;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  /* FAQ item styling */
  .faq-item {
    background-color: #111;
    margin: 1rem 0;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .faq-question {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-icon {
    font-size: 1.5rem;
    color: #00bfff;
  }
  
  .faq-answer {
    padding: 1rem;
    font-size: 0.95rem;
    color: #ddd;
    background-color: #222;
    border-top: 1px solid #333;
  }
  
  /* Expanded item styling */
  .faq-item.expanded .faq-question {
    color: #00bfff;
  }
  
  .faq-item.expanded .faq-icon {
    transform: rotate(180deg);
  }
  
  /* Hover effect */
  .faq-item:hover {
    background-color: #222;
  }
    
  .linkactive, .linkactive:visited {
    color: #007aff !important;
  }
  a{
    color: #007aff ;

  }